import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide52/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide52/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide52/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide52/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide52/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    Cómo brindar una buena experiencia al cliente en 2024 
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
    En un mundo donde la digitalización y la tecnología evolucionan a pasos agigantados, la experiencia del cliente se ha convertido en el centro de las estrategias de negocio para 2024.   
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
   Las expectativas de los consumidores son más altas que nunca, buscando no solo productos o servicios de calidad, sino también una experiencia de compra que sea tanto fluida como gratificante.   
<br /><br />
En este contexto, las marcas enfrentan el desafío de no solo mantenerse al día con las últimas tecnologías, sino también de comprender profundamente las necesidades y deseos de sus clientes para ofrecer experiencias personalizadas que generen lealtad y promuevan el engagement. 
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al Cliente ",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Utiliza datos para conocer al cliente.",
      },
      {
        key: 2,
        text: "Cómo personalizar la atención al cliente.",
      },
      {
        key: 3,
        text: "Crea una estrategia omnicanal.",
      },
      {
        key: 4,
        text: "Importancia de la cultura de innovación.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
